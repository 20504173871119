import 'bootstrap'
import '../scss/application.scss'

// Used for DELETE links and simple confirmation dialogs
import Rails from 'rails-ujs'
import Vue from 'vue'
import Value from '../common/Value'
import LeftLogo from '../common/LeftLogo'
import Logos from '../common/Logos'
import VersionTitle from '../common/VersionTitle'

Rails.start();

require.context('../images', true)

window.document.addEventListener('ncs-search', ({ detail: { resultType, action }}) => {
  window.gtag('event', 'page_view', { page_path: `/${resultType}/${action}` })
})

