<template>
  <h3 class="version-title">
    versión {{ number }} de {{ month }} de {{ year }}
  </h3>
</template>
<script>
  export default {
    props: ['number', 'month', 'year']
  }
</script>
<style lang="scss">
  .version-title {
    text-align: center;
    font-size: 22px;
    color: #6c757d;
  }
</style>
